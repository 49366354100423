.resource-request-form {
  margin: 0 auto;
  padding: 5px;
  margin-bottom: 3rem;
  .form-header {
    align-items: center;
    margin-bottom: 20px;
    span {
      margin-right: 5px;
    }
  }

  .form-fields {
    margin-top: 20px;
  }

  .styled-chip{
    padding: 5px;
    margin-top: 10px;
    margin-left:5px;
  }

  .form-fields-select{
    width: 100%;
  }

  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .skills-chips {
    display: flex;
    gap: 5px;
  }

  .form-actions-start {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
  }
  .action-box{
    position: absolute;
    bottom: 0px;
    background-color: #fff;
    width: calc(100% - 32px);
    text-align: end;
    padding: 16px 0px;
    z-index: 2;
  }
  .form-actions-edit {
    display: flex;
    justify-content: flex-end;
    background-color: white;
    gap: 10px;
    margin-top: 7rem !important;
    position: absolute;
    right: 10px;  
    width: auto;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
    bottom: 1rem; 
}}