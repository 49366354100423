@import './assets/css/variable/style.scss';

.pac-container { z-index: 100000 !important; }

.primary-color {
    color: $primary-color; 
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-none-underline {
    cursor: pointer;
    text-decoration: none;
}

.start-tab-space {
    padding-left: 0px !important;
}

.tabs-space {
    text-transform: capitalize !important;
    font-weight: 600 !important;
}

disable-text .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important;
    background-color: white !important;
}


.tiny-error .tox-tinymce {
    border: 1px solid red !important
}

.thumbnail {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.upload-img {
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    
    &.editable {
        cursor: pointer;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .edit-img {
        color: #fff;
        position: absolute;
        z-index: 15;
        bottom: 0;
        background-color: black;
        width: 100%;
        text-align: center;
        padding: 5px 0px;
        opacity: 0.7;

        &:hover {
            opacity: 0.9;
        }
    }
}

.profile-box {
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    padding: 10px 50px;
    max-width: 600px;
}

.password-box {
    min-height: 38vh;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    padding: 30px;
    max-width: 400px;
}

.form-border{
    border: 1px solid $border-color;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.rectangle-badge{
    background-color: #F3F4F5;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 400;
    color: #333333;
    font-size: 14px;
}