.table-skill-container {
    border: 1px solid #e3e3e3;
    border-radius: 2px;
    height: calc(100vh - 200px);

    .header{
        background-color: var(--background-primary);
        font-weight: 600;
        z-index: 1;
    }

    .category-cell{
        border-right: 2px solid #e0e0e0;
        word-break: normal;
        white-space: nowrap;
        text-align: center;
    }

    .set-width{
        max-width: 180px;
        min-width: 180px;
    }

    .style-table-cell{
        cursor: pointer;
        color: var(--primary-color);
    }

    .data-cell{
        text-align: center;
        border-right: 2px solid #e0e0e0;
        max-width: 80px;
        min-width: 80px;
    }

    .action-cell{
        border-left: 2px solid #e0e0e0;
        word-break: normal;
        white-space: nowrap;
        max-width: 60px;
        min-width: 60px;
        text-align: center;
    }
}

.pagination {
    margin-top: 10px;
}

.dialog-body {
    width: 100%;

    .skill-items-box{
        max-height: 300px;
        overflow-y: auto;
    }
}

.dialog-footer{
    margin: 10px 0;
}