.Input{
outline: none;
background-color: #E8E8E8;
border:none;
padding: 10px;
width: 40px;
border-radius: 5px;
align-items: center;
}

.action-box{
    position: absolute;
    bottom: 0px;
    background-color: #fff;
    width: calc(100% - 32px);
    text-align: end;
    padding: 16px 0px;

    button {
        margin-left: 12px;
    }
}

.action-buttons{
    display: flex;
    justify-content: space-between;
}

.cross-icon{
    margin-right: 20px;
    height: 30px;
}

.employee-box{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    .employee-inner-box{


        .update-heading-skill{
            padding-left: 30px;
            font-size: 16px;
        }

        .update-heading-employee{
            padding-left: 30px;
            font-size: 14px;
        }
    }
}